<template>
  <div class="course-container">
    <div class="main">
      <div id="tools" class="tools">
        <div class="title">
          <img src="../../../../assets/1.png" height="20" width="20" style="margin-right: 5px" />
          设置目标
        </div>
        <div class="tools-button-wrap">
          <!--        <div style="width: 48px;" @click="addLine">-->
          <!--          <i class="iconfont icon-lianxian-copy"></i>-->
          <!--        </div>-->
          <!--        <div style="width: 76px;" @click="onClick(tools[0].children[0])">新增目标</div>-->
          <!--        <div style="width: 48px;" @click="deleteRelation(selectedLine)">删除</div>-->
        </div>
        <div style="margin-right: 50px">
          <Button type="primary" @click="save">
            <Icon type="md-contract" :size="18" />
          </Button>
        </div>
      </div>
      <div>
        <div ref="myDiagramDiv" id="myDiagramDiv"></div>
      </div>
    </div>
  </div>
</template>

<script>
import courseApi from '@api/course'

export default {
  name: 'Chart',
  data() {
    return {
      chart: '',
      myDiagram: ''
    }
  },
  created() {
    this.initData()
  },
  methods: {
    save() {
      let t = JSON.parse(this.myDiagram.model.toJson())
      courseApi.saveTargets(t).then(res => {
        // console.log(res)
        if (res.res === true) {
          this.$router.push('/course/targetManagement')
        }
      })
    },
    initData() {
      courseApi.getTargets().then(res => {
        this.initChart(res.res)
      })
      // courseApi.getTargetRelations().then(res => {
      //   let data = []
      //   res.res.forEach(item => {
      //     data.push({ text: item.name })
      //   })
      //   this.initChart(data)
      // })
    },
    initChart(val) {
      var go = this.go
      var $ = go.GraphObject.make // for conciseness in defining templates

      this.myDiagram = $(
        go.Diagram,
        'myDiagramDiv', // must name or refer to the DIV HTML element
        {
          LinkDrawn: showLinkLabel, // this DiagramEvent listener is defined below
          LinkRelinked: showLinkLabel,
          'undoManager.isEnabled': true // enable undo & redo
        }
      )

      function nodeStyle() {
        return [
          new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(go.Point.stringify),
          {
            // the Node.location is at the center of each node
            locationSpot: go.Spot.Center
          }
        ]
      }

      function makePort(name, align, spot, output, input) {
        var horizontal = align.equals(go.Spot.Top) || align.equals(go.Spot.Bottom)
        return $(go.Shape, {
          fill: 'transparent', // changed to a color in the mouseEnter event handler
          strokeWidth: 0, // no stroke
          width: horizontal ? NaN : 8, // if not stretching horizontally, just 8 wide
          height: !horizontal ? NaN : 8, // if not stretching vertically, just 8 tall
          alignment: align, // align the port on the main Shape
          stretch: horizontal ? go.GraphObject.Horizontal : go.GraphObject.Vertical,
          portId: name, // declare this object to be a "port"
          fromSpot: spot, // declare where links may connect at this port
          fromLinkable: output, // declare whether the user may draw links from here
          toSpot: spot, // declare where links may connect at this port
          toLinkable: input, // declare whether the user may draw links to here
          cursor: 'pointer', // show a different cursor to indicate potential link point
          mouseEnter: function(e, port) {
            // the PORT argument will be this Shape
            if (!e.diagram.isReadOnly) port.fill = 'rgba(255,0,255,0.5)'
          },
          mouseLeave: function(e, port) {
            port.fill = 'transparent'
          }
        })
      }

      function textStyle() {
        return {
          font: 'bold 11pt Helvetica, Arial, sans-serif',
          stroke: '#333333'
        }
      }

      // define the Node templates for regular nodes
      this.myDiagram.nodeTemplateMap.add(
        '', // the default category
        $(
          go.Node,
          'Table',
          nodeStyle(),
          // the main object is a Panel that surrounds a TextBlock with a rectangular Shape
          $(
            go.Panel,
            'Auto',
            $(
              go.Shape,
              'Rectangle',
              { fill: 'white', stroke: '#555555', strokeWidth: 1 },
              new go.Binding('figure', 'figure'),
              new go.Binding('fill', 'color')
            ),
            $(
              go.TextBlock,
              textStyle(),
              {
                margin: 8,
                maxSize: new go.Size(160, NaN),
                wrap: go.TextBlock.WrapFit,
                editable: true
              },
              new go.Binding('text').makeTwoWay()
            )
          ),
          // four named ports, one on each side:
          makePort('T', go.Spot.Top, go.Spot.TopSide, false, true),
          makePort('L', go.Spot.Left, go.Spot.LeftSide, true, true),
          makePort('R', go.Spot.Right, go.Spot.RightSide, true, true),
          makePort('B', go.Spot.Bottom, go.Spot.BottomSide, true, false)
        )
      )
      // taken from ../extensions/Figures.js:
      go.Shape.defineFigureGenerator('File', function(shape, w, h) {
        var geo = new go.Geometry()
        var fig = new go.PathFigure(0, 0, true) // starting point
        geo.add(fig)
        fig.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0))
        fig.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h))
        fig.add(new go.PathSegment(go.PathSegment.Line, w, h))
        fig.add(new go.PathSegment(go.PathSegment.Line, 0, h).close())
        var fig2 = new go.PathFigure(0.75 * w, 0, false)
        geo.add(fig2)
        // The Fold
        fig2.add(new go.PathSegment(go.PathSegment.Line, 0.75 * w, 0.25 * h))
        fig2.add(new go.PathSegment(go.PathSegment.Line, w, 0.25 * h))
        geo.spot1 = new go.Spot(0, 0.25)
        geo.spot2 = go.Spot.BottomRight
        return geo
      })
      // replace the default Link template in the linkTemplateMap
      this.myDiagram.linkTemplate = $(
        go.Link, // the whole link panel
        {
          routing: go.Link.AvoidsNodes,
          curve: go.Link.JumpOver,
          corner: 5,
          toShortLength: 4,
          relinkableFrom: true,
          relinkableTo: true,
          reshapable: true,
          resegmentable: true,
          // mouse-overs subtly highlight links:
          mouseEnter: function(e, link) {
            link.findObject('HIGHLIGHT').stroke = 'rgba(30,144,255,0.2)'
          },
          mouseLeave: function(e, link) {
            link.findObject('HIGHLIGHT').stroke = 'transparent'
          },
          selectionAdorned: false
        },
        new go.Binding('points').makeTwoWay(),
        $(
          go.Shape, // the highlight shape, normally transparent
          {
            isPanelMain: true,
            strokeWidth: 8,
            stroke: 'transparent',
            name: 'HIGHLIGHT'
          }
        ),
        $(
          go.Shape, // the link path shape
          { isPanelMain: true, stroke: 'gray', strokeWidth: 2 },
          new go.Binding('stroke', 'isSelected', function(sel) {
            return sel ? 'dodgerblue' : 'gray'
          }).ofObject()
        ),
        $(
          go.Shape, // the arrowhead
          { toArrow: 'standard', strokeWidth: 0, fill: 'gray' }
        ),
        $(
          go.Panel,
          'Auto', // the link label, normally not visible
          {
            visible: false,
            name: 'LABEL',
            segmentIndex: 2,
            segmentFraction: 0.5
          },
          new go.Binding('visible', 'visible').makeTwoWay(),
          $(
            go.Shape,
            'RoundedRectangle', // the label shape
            { fill: '#F8F8F8', strokeWidth: 0 }
          ),
          $(
            go.TextBlock,
            'Yes', // the label
            {
              textAlign: 'center',
              font: '10pt helvetica, arial, sans-serif',
              stroke: '#333333',
              editable: true
            },
            new go.Binding('text').makeTwoWay()
          )
        )
      )

      // Make link labels visible if coming out of a "conditional" node.
      // This listener is called by the "LinkDrawn" and "LinkRelinked" DiagramEvents.
      function showLinkLabel(e) {
        var label = e.subject.findObject('LABEL')
        if (label !== null) label.visible = e.subject.fromNode.data.category === 'Conditional'
      }

      // temporary links used by LinkingTool and RelinkingTool are also orthogonal:
      // this.myDiagram.toolManager.linkingTool.temporaryLink.routing =
      //   go.Link.Orthogonal
      // this.myDiagram.toolManager.relinkingTool.temporaryLink.routing =
      //   go.Link.Orthogonal
      // // initialize the Palette that is on the left side of the page
      // $(
      //   go.Palette,
      //   'myPaletteDiv', // must name or refer to the DIV HTML element
      //   {
      //     nodeTemplateMap: this.myDiagram.nodeTemplateMap, // share the templates used by this.myDiagram
      //     model: newPaper go.GraphLinksModel([
      //       // specify the contents of the Palette
      //       { text: 'Step' }
      //     ])
      //   }
      // )
      let nodeData = $(go.GraphLinksModel)
      nodeData.nodeDataArray = val.nodeDataArray || []
      nodeData.linkDataArray = val.linkDataArray || []
      this.myDiagram.model = nodeData
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../../theme/variables';
.course-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  background-color: @layout-body-background;
  position: relative;
  box-sizing: border-box;
  .main {
    box-shadow: 0px 3px 10px rgba(60, 100, 209, 0.1);
    opacity: 1;
  }
  #myDiagramDiv {
    width: 100%;
    height: calc(100vh - 150px);
    background: url('../../../../assets/target_background.png');
    background-size: cover;
  }

  .tools {
    flex-shrink: 0;
    width: 100%;
    background-color: #fff;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .title {
      font-family: Source Han Sans CN;
      font-size: 16px;
      color: #333333;
      margin-left: 30px;
      display: flex;
      align-items: center;
    }

    .tools-button-wrap {
      display: flex;
      div {
        background: #4579ea;
        box-shadow: 0px 1px 8px rgba(60, 100, 209, 0.5);
        border-radius: 4px;
        color: #fffefe;
        margin-top: 11px;
        height: 32px;
        line-height: 32px;
        margin-left: 10px;
      }
      div:hover {
        background: #3c64d1;
        cursor: pointer;
      }
    }

    .ivu-btn-primary {
      background-color: #4579ea;
    }

    .buttons {
      padding: 0.1rem 0;
      height: 300px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a {
        display: block;
        color: #314659;
        width: 40px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;

        .iconfont {
          font-size: 24px;
        }

        &:hover {
          color: #1890ff;
        }
      }
    }
  }

  .canvas {
    flex: 1;
    width: 100%;
    position: relative;
    overflow: auto;
    background: #fff;

    .canvas-tip {
      width: 240px;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      position: absolute;
      border-radius: 3px;
      padding: 15px;
    }
  }
}
</style>
